import React from "react"
import { graphql } from "gatsby"
import DynamicZone from "../components/organisms/dynamic-zone"
const Page = ({
  data: {
    ft: { getInvolved: gi },
  },
}) => {
  return <DynamicZone components={gi.containers} />
}

export const query = graphql`
  query GetInvolved($locale: String) {
    ft {
      getInvolved(publicationState: LIVE, locale: $locale) {
        name
        slug
        containers {
          ...GetInvolvedDZ
        }
      }
    }
  }
`

export default Page
